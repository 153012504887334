class Component {
  constructor() {
    this.instances = {};
    this.UUID = 0;
  }

  register(data) {
    this.setInstance(this.UUID, data);
    this.UUID++;
  }

  setInstance(uuid, data) {
    this.instances[uuid] = Object.assign({}, this.instances[uuid], data);
  }

  getInstance(elem) {
    const uuid = Object.keys(this.instances).find(
      (key) => this.instances[key].element === elem
    );
    return this.instances[uuid];
  }

  getInstanceAll() {
    return this.instances;
  }
}

export default Component;
