/* eslint-disable no-console */

// import Accordion from '@mosquitodigitalltd/front-js/components/Accordion';
import Accordion from './components/Accordion';

class App {
  constructor() {
    this.components = [
      {
        src: Accordion,
        selector: '[data-accordion]'
      }
    ];

    // add js class to html
    document.documentElement.className = 'js';

    // init array of components
    if (this.components.length) {
      this.components.forEach((component) => {
        typeof component.src === 'object' && component.src.init();

        if (typeof component.src === 'function') {
          new component.src(component.selector, component.options).init();
        }
      });
    }

    // this.dynamicWebpackImport();
    // this.setupLazyLoad();
  }

  // dynamicWebpackImport() {
  //   if (document.querySelector('[data-helper="grid"]')) {
  //     import(
  //       /* webpackChunkName: 'helper' */ '@mosquitodigitalltd/front-js/components/Helper'
  //     ).then(component => component.default.init());
  //   }
  //
  //   if (document.querySelector('[data-modal]')) {
  //     import(
  //       /* webpackChunkName: 'modal' */ '@mosquitodigitalltd/front-js/components/Modal'
  //     ).then(component => {
  //       const modal = new component.default('[data-modal]', {
  //         on: {
  //           render: event => {
  //             console.log(event);
  //           },
  //           open: event => {
  //             console.log('Modal component open', event);
  //           },
  //           close: event => {
  //             console.log('Modal component close', event);
  //           }
  //         }
  //       });
  //       modal.init();
  //     });
  //   }
  //
  //   if (document.querySelector('[data-tabs]')) {
  //     import(
  //       /* webpackChunkName: 'tabs' */ '@mosquitodigitalltd/front-js/components/Tabs'
  //     ).then(component => {
  //       const tabs = new component.default('[data-tabs]', {
  //         on: {
  //           render: event => {
  //             console.log(event);
  //           },
  //           change: event => {
  //             console.log('Tabs component change', event);
  //           }
  //         }
  //       });
  //       tabs.init();
  //     });
  //   }
  //
  //   if (document.querySelector('[data-video-embed]')) {
  //     import(
  //       /* webpackChunkName: 'video-embed' */ '@mosquitodigitalltd/front-js/components/VideoEmbed'
  //     ).then(component => {
  //       const videoEmbed = new component.default('[data-video-embed]', {
  //         on: {
  //           render: event => {
  //             console.log(event);
  //           },
  //           create: event => {
  //             console.log('VideoEmbed component create', event);
  //           }
  //         }
  //       });
  //       videoEmbed.init();
  //     });
  //   }
  // }

  // setupLazyLoad() {
  //   const lazyLoad = new LazyLoad({
  //     root: document.querySelectorAll('[data-src]'),
  //     rootMargin: '20px 0px 20px 0px',
  //     threshold: 0
  //   });
  //   lazyLoad.render();
  // }
}

window.addEventListener('load', () => new App());
