import Component from './Component';
import { toggleAria } from '@mosquitodigitalltd/front-js/functions/toggleAria';
import { eventMatches } from '@mosquitodigitalltd/front-js/utils/eventMatches';
import { getQueryElement } from '@mosquitodigitalltd/front-js/utils/getQueryElement';

class Accordion extends Component {
  constructor(selector, options) {
    if (!selector) {
      throw new Error(`Bad element for Accordion: ${selector}`);
    }
    super();

    this.element = getQueryElement(selector);
    this.defaults = {
      collapseCurrentlyActive: true,
      urlSearchParams: true
    };
    this.options = Object.assign({}, options, this.defaults);
  }

  init() {
    this.render();
  }

  setActiveItem() {
    const activeItem = document.querySelectorAll(
      '.js-accordion__item.is-active'
    );

    for (let i = 0; i < activeItem.length; i += 1) {
      const heading = activeItem[i].querySelector('[data-accordion-heading]');
      const reveal = activeItem[i].querySelector('.js-accordion__reveal');
      const body = reveal.firstElementChild;

      reveal.style.transition = 'none';
      reveal.style.height = `${body.offsetHeight}px`;

      setTimeout(() => {
        reveal.style.removeProperty('transition');
      }, 300);

      // toggle aria-expanded
      toggleAria(heading, 'expanded');
    }
  }

  checkSearchParams() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('accordionId');
    const active = document.querySelector(`[data-accordion-heading="${id}"]`);

    if (id && active) {
      active.parentElement.classList.add('is-active');
    }

    this.setActiveItem();
  }

  toggle(item) {
    const heading = item.querySelector('[data-accordion-heading]');
    const reveal = item.querySelector('.js-accordion__reveal');
    const params = new URLSearchParams(window.location.search);

    if (item.classList.contains('is-active')) {
      item.classList.remove('is-active');
      reveal.style.height = '0px';

      // remove param from url via history api
      if (this.options.urlSearchParams) {
        params.delete('accordionId');
        window.history.replaceState({}, '', location.pathname);
      }

      // toggle aria-expanded
      toggleAria(heading, 'expanded');
    } else {
      const items = [...document.querySelectorAll('.js-accordion__item')];
      const id = heading.getAttribute('data-accordion-heading');
      const body = reveal.firstElementChild;

      // collapse other active items
      if (this.options.collapseCurrentlyActive) {
        items.forEach((item) => {
          if (item.classList.contains('is-active')) {
            const reveal = item.querySelector('.js-accordion__reveal');
            const heading = item.querySelector('[data-accordion-heading]');

            item.classList.remove('is-active');
            reveal.style.height = '0px';

            // toggle aria-expanded
            toggleAria(heading, 'expanded');
          }
        });
      }

      item.classList.add('is-active');
      reveal.style.height = `${body.offsetHeight}px`;

      // update url with param via history api
      if (this.options.urlSearchParams) {
        params.set('accordionId', id);
        window.history.replaceState({}, '', `${location.pathname}?${params}`);
      }

      // toggle aria-expanded
      toggleAria(heading, 'expanded');
    }
  }

  addListeners() {
    this.element.forEach((elem) => {
      elem.addEventListener('click', (event) => {
        const item = eventMatches(event, '.js-accordion__item');
        if (item && !eventMatches(event, '.js-accordion__reveal')) {
          this.toggle(item);
        }
      });
    });
  }

  render() {
    if (!this.element.length) return;

    // register
    this.element.forEach((elem) => {
      this.register({
        element: elem
      });

      // parse json object from data-accordion
      const options = JSON.parse(elem.getAttribute('data-accordion'));

      // assign to default options
      this.options = Object.assign({}, this.options, options);
      this.options.urlSearchParams
        ? this.checkSearchParams()
        : this.setActiveItem();
    });

    // add listeners
    this.addListeners();

    // render callback
    const render = this.options.on ? this.options.on.render : null;
    if (render && typeof render === 'function') {
      render(this.options);
    }
  }
}

export default Accordion;
